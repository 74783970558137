export type ClientConfig = {
  graphqlBaseUrl: string;
  imageServiceUrl: string;
  justGivingRootUrl: string;
  activeGroupsCookieName: string;
  authCookieName: string;
  iamUrl: string;
  facebookAppId: string;
  facebookAppNamespace: string;
  assetPrefix: string;
  imageServiceWriteUrl: string;
  qrCodeUrl: string;
  linkServiceUrl: string;
  linkServiceStatelessDonateUrl: string;
  featureFlags: {
    statelessCheckoutEnabled: boolean;
  };
  optimizelyFullstackKey: string;
  analyticsCookieName: string;
  cspReportUrl: string;
};
// The current recommended way to pass configuration to client components is to use
// [environment variables](https://nextjs.org/docs/api-reference/next.config.js/environment-variables)
// defined in `next.config.js`
//
// There's no need to validate the config data as it should already be validated by TypeScript
// thanks to the `@ts-check` directive in the config files.
const clientConfig = JSON.parse(process.env.CLIENT_CONFIG!) as ClientConfig;

export default clientConfig;
