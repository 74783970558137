import { ReactNode } from 'react';
import styles from './ErrorPage.module.scss';

type Props = {
  imageUrl: string;
  children: ReactNode;
};

export const ErrorPage: React.FC<Props> = ({ children, imageUrl }) => {
  return (
    <div className={styles.background}>
      <div className={styles.container}>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img className={styles.image} src={imageUrl} alt="" />
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};
